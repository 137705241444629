import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Golongan A",
        attr: {
          "feno_golA[tanaman_golA]": "Nama Tanaman",
          "feno_golA[peramatan_golA]": "Peramatan Fenologi",
          "feno_golA[fenomena_golA]": "Keadaan Fenomena",
          "feno_golA[ket_feno_golA]": "Keterangan",
        },
      },

      {
        label: "Golongan B",
        attr: {
          "feno_golB[tanaman_golB]": "Nama Tanaman",
          "feno_golB[peramatan_golB]": "Peramatan Fenologi",
          "feno_golB[fenomena_golB]": "Keadaan Fenomena",
          "feno_golB[ket_feno_golB]": "Keterangan",
        },
      },

      {
        label: "Golongan C",
        attr: {
          "feno_golC[tanaman_golC]": "Nama Tanaman",
          "feno_golC[peramatan_golC]": "Peramatan Fenologi",
          "feno_golC[fenomena_golC]": "Keadaan Fenomena",
          "feno_golC[ket_feno_golC]": "Keterangan",
        },
      },
      {
        label: "Observer",
        attr: {
            // "observer_id" : "ID observer",
            "observer_name" : "Nama observer "
            },
    },
    ];

    return listAttr;
  },
};
