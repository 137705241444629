import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
        {
            label: "Suhu Tanah rumput",
            attr: {
                "m_0730ws[ttr_0cm_0730]" : "Suhu tanah rumput kedalaman 0cm di jam 07.30",
                "m_0730ws[ttr_2cm_0730]" : "Suhu tanah rumput kedalaman 2cm di jam 07.30",
                "m_0730ws[ttr_5cm_0730]" : "Suhu tanah rumput kedalaman 5cm di jam 07.30",
                "m_0730ws[ttr_10cm_0730]" : "Suhu tanah rumput kedalaman 10cm di jam 07.30",
                "m_0730ws[ttr_20cm_0730]" : "Suhu tanah rumput kedalaman 20cm di jam 07.30",
                "m_1330ws[ttr_0cm_1330]" : "Suhu tanah rumput kedalaman 0cm di jam 13.30",
                "m_1330ws[ttr_2cm_1330]" : "Suhu tanah rumput kedalaman 2cm di jam 13.30",
                "m_1330ws[ttr_5cm_1330]" : "Suhu tanah rumput kedalaman 5cm di jam 13.30",
                "m_1330ws[ttr_10cm_1330]" : "Suhu tanah rumput kedalaman 10cm di jam 13.30",
                "m_1330ws[ttr_20cm_1330]" : "Suhu tanah rumput kedalaman 20cm di jam 13.30",
                "m_1730ws[ttr_0cm_1730]" : "Suhu tanah rumput kedalaman 0cm di jam 17.30",
                "m_1730ws[ttr_2cm_1730]" : "Suhu tanah rumput kedalaman 2cm di jam 17.30",
                "m_1730ws[ttr_5cm_1730]" : "Suhu tanah rumput kedalaman 5cm di jam 17.30",
                "m_1730ws[ttr_10cm_1730]" : "Suhu tanah rumput kedalaman 10cm di jam 17.30",
                "m_1730ws[ttr_20cm_1730]" : "Suhu tanah rumput kedalaman 20cm di jam 17.30",       
                "m_1730ws[ttr_50cm_1730]" : "Suhu tanah rumput kedalaman 50cm di jam 17.30", 
                "m_1730ws[ttr_100cm_1730]" : "Suhu tanah rumput kedalaman 100cm di jam 17.30", 
                },
        },
        {
            label: "Suhu Tanah gundul",
            attr: {
                "m_0730ws[ttg_0cm_0730]" : "Suhu tanah gundul kedalaman 0cm di jam 07.30",
                "m_0730ws[ttg_2cm_0730]" : "Suhu tanah gundul kedalaman 2cm di jam 07.30",
                "m_0730ws[ttg_5cm_0730]" : "Suhu tanah gundul kedalaman 5cm di jam 07.30",
                "m_0730ws[ttg_10cm_0730]" : "Suhu tanah gundul kedalaman 10cm di jam 07.30",
                "m_0730ws[ttg_20cm_0730]" : "Suhu tanah gundul kedalaman 20cm di jam 07.30",
                "m_1330ws[ttg_0cm_1330]" : "Suhu tanah gundul kedalaman 0cm di jam 13.30",
                "m_1330ws[ttg_2cm_1330]" : "Suhu tanah gundul kedalaman 2cm di jam 13.30",
                "m_1330ws[ttg_5cm_1330]" : "Suhu tanah gundul kedalaman 5cm di jam 13.30",
                "m_1330ws[ttg_10cm_1330]" : "Suhu tanah gundul kedalaman 10cm di jam 13.30",
                "m_1330ws[ttg_20cm_1330]" : "Suhu tanah gundul kedalaman 20cm di jam 13.30",
                "m_1730ws[ttg_0cm_1730]" : "Suhu tanah gundul kedalaman 0cm di jam 17.30",
                "m_1730ws[ttg_2cm_1730]" : "Suhu tanah gundul kedalaman 2cm di jam 17.30",
                "m_1730ws[ttg_5cm_1730]" : "Suhu tanah gundul kedalaman 5cm di jam 17.30",
                "m_1730ws[ttg_10cm_1730]" : "Suhu tanah gundul kedalaman 10cm di jam 17.30",
                "m_1730ws[ttg_20cm_1730]" : "Suhu tanah gundul kedalaman 20cm di jam 17.30",      
                "m_1730ws[ttg_50cm_1730]" : "Suhu tanah gundul kedalaman 50 Cm di jam 17.30",
                "m_1730ws[ttg_100cm_1730]" : "Suhu tanah gundul kedalaman 100 Cm di jam 17.30"
                },
        },
        {
            label: "Observer",
            attr: {
                // "observer_id" : "ID observer",
                "observer_name" : "Nama observer "
                },
        },

        
        ]
        return listAttr;
    }
    
}
