import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
        {
            label: "AGM1A",
            attr: {
                "m_0700ws[tbk_1c2m_0700]" : "Suhu bola kering 1.2 m 07.00",
                "m_0700ws[tbb_1c2m_0700]" : "suhu bola basah ketinggian 1.2m 0700",
                "m_1400ws[tbk_1c2m_1400]" : "Suhu bola kering 1.2 m 14.00",
                "m_1400ws[tbb_1c2m_1400]" : "suhu bola basah ketinggian 1.2m 14.00",
                "m_1800ws[tbk_1c2m_1800]" : "Suhu bola kering 1.2 m 18.00",
                "m_1800ws[tbb_1c2m_1800]" : "suhu bola basah ketinggian 1.2m 18.00",
                "m_0700ws[tn_rumput_0700]" : "suhu minimum rumput 07.00",
                "m_1400ws[t_min_uji_1400]" : "suhu minimum (Tmin) reset jam 14 .00",
                "m_1800ws[t_max_1c2m]" : "suhu maksimum di ketinggian 1.2m ",
                "m_0700ws[rh_1c2m_0700]" : "rel hum ketinggian 1.2m jam 07.00",
                "m_1400ws[rh_1c2m_1400]" : "rel hum ketinggian 1.2m jam 14.00",
                "m_1800ws[rh_1c2m_1800]" : "rel hum ketinggian 1.2m jam 18.00",
                "m_0700ws[ws_avg_0c5_0700]" : "Kecepatan angin rata-rata,  tingginya 0.5 m di jam 07.00",
                "m_1400ws[ws_avg_7_1400]" : "Kecepatan angin rata-rata, tingginya 7m di jam 14.00",
                "m_1800ws[ws_avg_7_1800]" :"Kecepatan angin rata-rata,  tingginya 7 m jam 18.00",
                // "m_1800ws[ws_avg_0c5_1800]" : "Kecepatan angin rata-rata,  tingginya 0.5 m jam 18.00",
                "m_0700ws[wd_0700]" : "arah angin jam 07.00",
                "m_1400ws[wd_1400]" : "arah angin jam 14.00",
                "m_1800ws[wd_1800]" : "arah angin jam 18.00",
                "m_0700ws[ws_0700]" : "kecepatan angin jam 07.00",
                "m_1400ws[ws_1400]" : "kecepatan angin jam 14.00",
                "m_1800ws[ws_1800]" : "kecepatan angin jam 18.00",
                "m_0700ws[ss_12]" : "lama penyinaran jam 12",
                "m_0700ws[rr_0700]" : "Curah hujan pada jam 07.00",
                "m_1400ws[tbk_1c2m_1400]" : "Suhu bola kering 1.2 m pada jam 14.00",
                "m_1400ws[t_min_uji_1400]" : "suhu minimum (Tmin) reset jam 14 .00",
                "m_1800ws[tbk_1c2m_1800]" : "Suhu bola kering 1.2 m pada jam 18.00",
                "m_1800ws[t_reset_1c2m_1800]" : "suhu minimum (Tmin) reset jam 18WS",
                "m_0700ws[co_cu_0700]" : "keadaan cuaca jam 0700",
                "m_1400ws[co_cu_1400]" : "Keadaan cuaca jam 14.00",
                "m_0700ws[ku_hujan]" : "keadaan udara hujan",
                "m_0700ws[ku_pembekuan]" : "keadaan udara pembekuan",
                "m_0700ws[ku_embun]" : "keadaan udara embun",
                "m_0700ws[ku_kabut]" : "keadaan udara kabut",
                "m_0700ws[ku_rambun]" : "keadaan udara rambun",
                "m_0700ws[ku_tanpa_rambun]" : "keadaan udara tanpa rambun",
                "m_0700ws[ku_24]" : "keadaan udara dalam 24 jam",
                "m_0700ws[ku_kabut_debu]" : "keadaan udara kabut debu",
                "m_0700ws[ku_salju]" : "keadaan udara salju",
                "m_0700ws[ku_angin_ribut]" : "keadaan udara angin ribut",
                },
        },
        {
            label: "Observer",
            attr: {
                // "observer_id" : "ID observer",
                "observer_name" : "Nama observer "
                },
        },
        ]
        return listAttr;
    }
    
}
