// import axios from "axios";
// import jwt from "@/auth/jwt/useJwt";
// import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
        {
            label: "Angin",
            attr: {
                "wind_indicator_iw":"Pengenal Data Angin (iw)",
                "wind_dir_deg_dd":"Arah Angin (derajat)",
                "wind_speed_ff":"Kecepatan Angin (knot)",
                "visibility_vv":"Jarak penglihatan mendatar (km)",
                },  
        },
        {
            label: "Cuaca",
            attr: {
                "weather_indicator_ix":"Pengenal Data Cuaca (ix)",
                "present_weather_ww":"Cuaca Saat Pengamatan (ww)",
                "past_weather_w1":"Cuaca yang lalu (W1)",
                "past_weather_w2":"Cuaca yang lalu (W2)",
                },  
        },
        {
            label: "Tekanan",
            attr: {
                "pressure_temp_c":"Derajat Panas (°C)",
                "pressure_reading_mb":"Tekanan dibaca (mb)",
                "pressure_qfe_mb_derived":"Tekanan QFE",
                "pressure_qff_mb_derived":"Tekanan QFF",
                "pressure_tend_3h_mb_ppp" : "Selisih tekanan 3 jam ",
                "pressure_tend_24h_mb_ppp" : "Selisih tekanan 24 jam ",
                },  
        },
        {
            label: "Suhu",
            attr: {
                "temp_drybulb_c_tttttt":"Suhu Bola Kering (°C)",
                "temp_dewpoint_c_tdtdtd" : "Suhu Titik Embun (°C)",
                "temp_wetbulb_c":"Suhu Bola basah (°C)",
                "temp_max_c_txtxtx":"Suhu Maksimum (°C)",
                "temp_min_c_tntntn":"Suhu Minimum (°C)",
                "relative_humidity_pc" : "Relative Humidity"
                },  
        },
        {
            label: "Hujan",
            attr: {
                "rainfall_last_mm":"Hujan ditakar (mm)",
                "rainfall_24h_rrrr" : "Hujan 24 jam ",
                "rainfall_6h_rrr" : "Hujan 6 jam",
                "rainfall_indicator_ir" : "Pengenal data hujan",
                },  
        },
        {
            label: "Awan Rendah",
            attr: {
                "cloud_low_type_cl":"CL",
                "cloud_low_cover_oktas":"NCL",
                "cloud_low_type_1":"Jenis 1",
                "cloud_low_type_2":"Jenis 2",
                "cloud_low_type_3":"Jenis 3",
                "cloud_low_cover_1":"Jumlah 1",
                "cloud_low_cover_2":"Jumlah 2",
                "cloud_low_cover_3":"Jumlah 3",
                "cloud_low_base_1":"Tinggi Dasar (m) 1",
                "cloud_low_base_2":"Tinggi Dasar (m) 2",
                "cloud_low_base_3":"Tinggi Dasar (m) 3",
                "cloud_low_peak_1":"Tinggi Puncak (m) 1",
                "cloud_low_peak_2":"Tinggi Puncak (m) 2",
                "cloud_low_dir_1":"Arah Gerak 1",
                "cloud_low_dir_2":"Arah Gerak 2",
                "cloud_low_dir_3":"Arah Gerak 3",
                "cloud_elevation_angle_ec_1":"Sudut Elevasi 1",
                "cloud_elevation_angle_ec_2":"Sudut Elevasi 2",
                "cloud_low_dir_true_da_1":"Arah Sebenarnya 1",
                "cloud_low_dir_true_da_2":"Arah Sebenarnya 2",
                "cloud_layer_1_type_c" : "Jenis awan 1",
                "cloud_layer_2_type_c" : "Jenis awan 2",
                "cloud_layer_1_height_m_hshs" : "Tinggi dasar awan 1",
                "cloud_layer_2_height_m_hshs" : "Tinggi dasar awan 2",
                "cloud_layer_1_amt_oktas_ns" : "Tutupan awan 1",
                "cloud_layer_2_amt_oktas_ns" : "Tutupan awan 2",
                "cloud_layer_3_type_c" : "Jenis awan 3",
                "cloud_layer_3_height_m_hshs" : "Tinggi dasar awan 3",
                "cloud_layer_3_amt_oktas_ns" : "Tutupan awan 3",
                "cloud_layer_4_type_c" : "Jenis awan 4",
                "cloud_layer_4_height_m_hshs" : "Tinggi dasar awan 4",
                "cloud_layer_4_amt_oktas_ns" : "Tutupan awan 4",
                },  
        },
        {
            label: "Awan Menengah",
            attr: {
                "cloud_med_type_cm":"CM",
                "cloud_med_cover_oktas":"NCM",
                "cloud_med_type_1":"Jenis 1",
                "cloud_med_type_2":"Jenis 2",
                "cloud_med_cover_1":"Jumlah 1",
                "cloud_med_cover_2":"Jumlah 2",
                "cloud_med_base_1":"Tinggi Dasar (m) 1",
                "cloud_med_base_2":"Tinggi Dasar (m) 2",
                "cloud_med_dir_1":"Arah 1",
                "cloud_med_dir_2":"Arah 2",
                },  
        },
        {
            label: "Awan Tinggi",
            attr: {
                "cloud_high_type_ch":"CH",
                "cloud_high_cover_oktas":"NCH",
                "cloud_high_type_1":"Jenis 1",
                "cloud_high_type_2":"Jenis 2",
                "cloud_high_cover_1":"Jumlah 1",
                "cloud_high_cover_2":"Jumlah 2",
                "cloud_high_base_1":"Tinggi Dasar (m) 1",
                "cloud_high_base_2":"Tinggi Dasar (m) 2",
                "cloud_high_dir_1":"Arah 1",
                "cloud_high_dir_2":"Arah 2",
                },  
        },
        {
            label: "Parameter Lain",
            attr: {
                "cloud_cover_oktas_m":"Bagian Langit Tertutup Awan (oktas)",
                "cloud_vertical_vis":"Jarak penglihatan vertikal (meter)",               
                "evaporation_24hours_mm_eee":"Penguapan (mm)",
                "evaporation_eq_indicator_ie":"Pengenal Data Penguapan(IE)",
                "solar_rad_24h_jcm2_f24":"Radiasi Matahari (Joule/cm2)",
                "sunshine_h_sss":"Lama Penyinaran Matahari (jam)",
                "land_cond":"Keadaan Tanah",
                "land_note":"Catatan Tanah",
                "encoded_synop":"Sandi",
                // "observer_id" : "ID observer",
                // "observer_name" : "Nama observer ",
                },  
        },
        {
            label: "Observer",
            attr: {
                "observer_name" : "Nama observer ",
                },  
        },
        ]
        return listAttr;
    }
    
}







