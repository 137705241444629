import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "KAH",
        attr: {
          "kah_id_sampel":"Sampel ID",
          // "tgl_pasang":"Tanggal Pasang Pasang",
          // "tgl_angkat":"Tanggal Angkat",
          "kah_ph":"pH",
          "kah_ph_flag":"flag_pH",
          "kah_conductivity":"DH",
          "kah_conductivity_flag":"flag_DH",
          "kah_na":"Na",
          "kah_na_flag":"flag_Na",
          "kah_nh4":"NH4",
          "kah_nh4_flag":"flag_NH4",
          "kah_k":"K",
          "kah_k_flag":"flag_K",
          "kah_mg2":"Mg",
          "kah_mg2_flag":"flag_Mg",
          "kah_ca2":"Ca",
          "kah_ca2_flag":"flag_Ca",
          "kah_cl":"Cl",
          "kah_cl_flag":"flag_Cl",
          "kah_no3":"NO3",
          "kah_no3_flag":"flag_NO3",
          "kah_so42":"SO4",
          "kah_so42_flag":"Flag_SO4",
          "kah_acidity":"Acidity",
          "kah_acidity_flag":"flag_Acidity",
          "kah_alkalinity":"Alkalinity",
          "kah_alkalinity_flag":"flag_Alkalinity",
          "kah_curah_hujan_sampling":"curah hujan",
          "kah_curah_hujan_sampling_flag":"flag_ch",
          "kah_massa_sampling":"massa sampling",
          "kah_massa_sampling_flag":"flag_massasampling",
          "kah_kualitas_sampel":"kualitas sampling",
          "kah_catatan_lab":"catatan_lab",
          "kah_kejadian_luar_biasa":"kejadian luar biasa",
          "kah_catatan_lainnya":"catatan lainnya",
          "kah_ptgs_pasang_sampling":"petugas awal sampling",
          "kah_ptgs_angkat_sampling":"petugas akhir sampling",
          "kah_penyelia":"petugas analisis lab",

        },
      },
    //   {
    //     label: "Observer",
    //     attr: {
    //         "observer_id" : "ID observer",
    //         "observer_name" : "Nama observer "
    //         },
    // },
    ];

    return listAttr;
  },
};
