import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Suhu Udara",
        attr: {
          t_thermo_01: "01:00",
          t_thermo_02: "02:00",
          t_thermo_03: "03:00",
          t_thermo_04: "04:00",
          t_thermo_05: "05:00",
          t_thermo_06: "06:00",
          t_thermo_07: "07:00",
          t_thermo_08: "08:00",
          t_thermo_09: "09:00",
          t_thermo_10: "10:00",
          t_thermo_11: "11:00",
          t_thermo_12: "12:00",
          t_thermo_13: "13:00",
          t_thermo_14: "14:00",
          t_thermo_15: "15:00",
          t_thermo_16: "16:00",
          t_thermo_17: "17:00",
          t_thermo_18: "18:00",
          t_thermo_19: "19:00",
          t_thermo_20: "20:00",
          t_thermo_21: "21:00",
          t_thermo_22: "22:00",
          t_thermo_23: "23:00",
          t_thermo_24: "24:00",
          tx_thermo: "Maksimum",
          tn_thermo: "Minimum",
          t_avg_thermo: "Rata-Rata",
        },
      },

      {
        label: "Kelembaban Udara",
        attr: {
          rh_higro_01: "01:00",
          rh_higro_02: "02:00",
          rh_higro_03: "03:00",
          rh_higro_04: "04:00",
          rh_higro_05: "05:00",
          rh_higro_06: "06:00",
          rh_higro_07: "07:00",
          rh_higro_08: "08:00",
          rh_higro_09: "09:00",
          rh_higro_10: "10:00",
          rh_higro_11: "11:00",
          rh_higro_12: "12:00",
          rh_higro_13: "13:00",
          rh_higro_14: "14:00",
          rh_higro_15: "15:00",
          rh_higro_16: "16:00",
          rh_higro_17: "17:00",
          rh_higro_18: "18:00",
          rh_higro_19: "19:00",
          rh_higro_20: "20:00",
          rh_higro_21: "21:00",
          rh_higro_22: "22:00",
          rh_higro_23: "23:00",
          rh_higro_24: "24:00",
          rh_max_higro: "Maksimum",
          rh_min_higro: "Minimum",
          rh_avg_higro: "Rata-Rata",
        },
      },
      {
        label: "Observer",
        attr: {
            // "observer_id" : "ID observer",
            "observer_name" : "Nama observer "
            },
    },
    ];

    return listAttr;
  },
};
