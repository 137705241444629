import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Aerosol",
        attr: {
          "aerosol_id_sampel":"Sampel ID",
          // "tgl_pasang":"Tanggal Pemasangan",
          // "tgl_angkat":"Tanggal Pengangkatan",
          "aerosol_ph":"pH",
          "aerosol_ph_flag":"pH Flag",
          "aerosol_conductivity":"Electro-Conductivity",
          "aerosol_conductivity_flag":"Electro-Conductivity Flag",
          "aerosol_cl":"Cl- (klorida)",
          "aerosol_cl_flag":"Cl- (klorida) Flag",          
          "aerosol_so42":"SO42- (Sulfat)",
          "aerosol_so42_flag":"SO42- (Sulfat) Flag",         
          "aerosol_no3":"NO3- (Nitrat)",
          "aerosol_no3_flag":"NO3- (Nitrat) Flag",          
          "aerosol_na":"Na+ (Natrium)",
          "aerosol_na_flag":"Na+ (Natrium) Flag",        
          "aerosol_nh4":"NH4+ (ammonium)",
          "aerosol_nh4_flag":"NH4+ (ammonium) Flag",         
          "aerosol_k":"K+ (Kalium)",
          "aerosol_k_flag":"K+ (Kalium) Flag",         
          "aerosol_mg2":"Mg2+ (Magnesium)",
          "aerosol_mg2_flag":"Mg2+ (Magnesium) Flag",          
          "aerosol_ca2":"Ca2+ (Kalsium)",
          "aerosol_ca2_flag":"Ca2+ (Kalsium) Flag",
          "aerosol_konsentrasi":"Konsentrasi Aerosol/PM",
          "aerosol_bobot_filter_kosong":"Bobot Filter Kosong",
          "aerosol_bobot_filter_sampling":"Bobot Filter Sampling",
          "aerosol_flow_rate_awal":"Flow Rate Awal",
          "aerosol_flow_rate_akhir":"Flow Rate Akhir",
          "aerosol_total_waktu_sampling":"Total Waktu Sampling (menit)",
          "aerosol_tekanan_udara_awal":"Tekanan udara awal",
          "aerosol_tekanan_udara_akhir":"Tekanan udara akhir",
          "aerosol_temperatur_awal":"Temperature awal",
          "aerosol_temperatur_akhir":"Temperature akhir",
          "aerosol_ptgs_pasang_sampling":"Petugas Awal Sampling",
          "aerosol_ptgs_angkat_sampling":"Petugas Akhir Sampling",
          "ptgs_analis":"Petugas Analis Lab",
          "aerosol_kondisi_cuaca_awal":"Kondisi cuaca awal",
          "aerosol_kondisi_cuaca_akhir":"Kondisi cuaca akhir",
          "aerosol_kondisi_sampling":"Kondisi sampling",
          "aerosol_kejadian_lainnya":"Catatan Lainnya lainnya"
        },
      },
    //   {
    //     label: "Observer",
    //     attr: {
    //         "observer_id" : "ID observer",
    //         "observer_name" : "Nama observer "
    //         },
    // },
    ];

    return listAttr;
  },
};
