// import axios from "axios";
// import jwt from "@/auth/jwt/useJwt";
// import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [

        {
            label: "Angin",
            attr: {
                m_wind_dir_ddd : "Arah Angin (°)",
                m_vrb : "VRB",
                m_wind_speed_ff : "Kecepatan Angin (knot)",
                m_wind_gust_fmfm : "Gust (Knot)",
                m_dxdxdx : "Arah angin min",
                m_dndndn : "Arah angin max",
                },  
        },
        {
            label: "Cuaca",
            attr: {
                m_present_weather_ww_1 : "Saat Pengamatan Group 1",
                m_present_weather_ww_2 : "Saat Pengamatan Group 2",
                m_present_weather_ww_3 : "Saat Pengamatan Group 3",
                m_recent_weather_ww_1 : "Yang Lalu Group 1",
                m_recent_weather_ww_2 : "Yang Lalu Group 2",
                m_recent_weather_ww_3 : "Yang Lalu Group 3",
                },  
        },

        {
            label: "Visibility",
            attr: {
                m_prevailing_visibility_vvvv : "Prevailling (m)",
                m_visibiility_min_vnvnvnvn : "Minimum",
                m_visibility_min_direction_dv : "Min Vis Direction",
                m_ndv : "NDV",
                },  
        },
        {
            label: "RVR",
            attr: {
                "m_rvr[designator_drdr]" : "Designator",
                "m_rvr[vrvrvrvr]" : "VR VR VR VR",
                // "m_rvr[variation_vrvrvrvr_min]" : "VR VIS MIN",
                // "m_rvr[variation_vrvrvrvr_max]" : "VR VIS MAX",
                "m_rvr[instrument_limit]" : "INS Limit",
                "m_rvr[tendency_i]" : "Tendency",
                },  
        },
        {
            label: "Awan",
            attr: {
                "m_cloud_clear" : "Clear",
                "m_cloud[amount_nsnsns]" : "Jumlah",
                "m_cloud[height_hshshs]" : "Tinggi (Feet)",
                "m_cloud[type]" : "TYPE",
                "m_vertical_visibility_vvhshshs" : "Vertical Visibility",
                },  
        },
        {
            label: "Wind Shear Runaway",
            attr: {
                "m_wind_shear_all_runway" : "WS All Runway",
                "m_wind_shear[runway_ind_drdr]" : "Wind Shear Designator",
                "m_runway[state_ind_drdr]" : "DESIGNATOR",
                "m_runway[depos_state_er]" : "STATE",
                "m_runway[depos_cr]" : "Contamination",
                "m_runway[depos_depth_erer]" : "Depth",
                "m_runway[depos_braking_brbr]" : "Braking Coefficient",
                },  
        },
        {
            label: "Trend",
            attr: {
                "m_trend" : "Trend",
                "m_trend_tt_1" : "Indicator 1",
                "m_trend_tt_2" : "Indicator 2",
                "m_trend_gggg_1" : "Time 1",
                "m_trend_gggg_2" : "Time 1",
                "m_trend_wind_dir_dd" : "Arah Angin (°)",
                "m_trend_wind_speed_ff" : "Kecepatan Angin (knot)",
                "m_trend_wind_gust_fmfm" : "Gust (Knot)",
                "m_trend_cavok" : "Report CAVOK",
                "m_trend_vvvv" : "Prevailling (m)",
                "m_trend_nsw" : "No Significant Weather / NSW",
                "m_trend_ww" : "Forecast Weather",
                "m_trend_nsc" : "No Significant Cloud / NSC",
                "m_trend_cloud[nsnsns]" : "Jumlah",
                "m_trend_cloud[height_hshshs]" : "Tinggi (Feet)",
                "m_trend_cloud[type]" : "Type",
                "m_trend_vv_vvhshshs" : "Vertical Visibility",
                },  
        },
        {
            label: "Suhu dan Tekanan",
            attr: {
                m_air_temperature_tt : "Suhu (°C)",
                m_dew_point_temp_tdtd : "Titik Embun (°C)",
                m_sea_surface_temperature_tsts : "Suhu permukaan laut (°C)",
                m_qnh_pressure_phphph : "Tekanan Udara QNH (mb)",
                },  
        },
        {
            label: "Parameter Lain",
            attr: {
                m_cor : "COR",
                m_nil : "NIL",
                m_auto : "AUTO",
                m_cavok : "Report CAVOK",
                m_sea_state_s : "Keadaan laut",
                m_remark : "Catatan",
                encoded_metar_speci : "Sandi",
                },  
        },
        {
            label: "Observer",
            attr: {
                observer_name : "Nama observer ",
                },  
        },
        ]
        return listAttr;
    }
    
}















