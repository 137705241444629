import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
        {
            label: "Gunbellani",
            attr: {
                "m_0700ws[h_air_gunbellani_1]" : "Tinggi Air Pembacaan I",
                "m_0700ws[h_air_gunbellani_2]" : "Tinggi Air Pembacaan II",
                "m_0700ws[h_air_diff_gunbellani]" : "Selisih Pembacaan I hari ini - II hari kemarin",
                "m_0700ws[rad_gunbellani]" : "Radiasi MTH (cal/cm2)",
                "m_0700ws[cat_gunbellani]" : "catatan gunbellani",
                   
                },  
        },
        {
            label: "Observer",
            attr: {
                // "observer_id" : "ID observer",
                "observer_name" : "Nama observer "
                },
        },
        ]
        return listAttr;
    }
    
}
