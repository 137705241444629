import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Lama Penyinaran",
        attr: {
          ss_6_7: "Jam 6-7",
          ss_7_8: "Jam 7-8",
          ss_8_9: "Jam 8-9",
          ss_9_10: "Jam 9-10",
          ss_10_11: "Jam 10-11",
          ss_11_12: "Jam 11-12",
          ss_12_13: "Jam 12-13",
          ss_13_14: "Jam 13-14",
          ss_14_15: "Jam 14-15",
          ss_15_16: "Jam 15-16",
          ss_16_17: "Jam 16-17",
          ss_17_18: "Jam 17-18"
        },
      },
      {
        label: "Jumlah Lama Penyinaran",
        attr: {
          ss_total_8: "Jumlah 8 Jam",
          ss_total_12: "Jumlah 12 Jam",
        },
      },
      {
        label: "Observer",
        attr: {
            // "observer_id" : "ID observer",
            "observer_name" : "Nama observer "
            },
    },
    ];

    return listAttr;
  },
};
