import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
        {
            label: "Piche Penguapan",
            attr: {
                "m_0730ws[h_piche_0730]" : "tinggi piche di jam 07.30",
                "m_1330ws[h_piche_1330]" : "tinggi piche di jam 13.30",
                "m_1730ws[h_piche_1730]" : "tinggi piche di jam 17.30",
                "m_0730ws[eva_piche_0730]" : "selisih penguapan piche di jam 07.30",
                "m_1330ws[eva_piche_1330]" : "selisih penguapan piche di jam 13.30",
                "m_1730ws[eva_piche_1730]" : "selisih penguapan piche di jam 17.30",

                },
        },
        {
            label: "Observer",
            attr: {
                // "observer_id" : "ID observer",
                "observer_name" : "Nama observer "
                },
        },
        ]
        return listAttr;
    }
    
}
