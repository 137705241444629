import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Kedalaman Tanah",
        attr: {
          soilmoisture_10: " 10 cm",
          soilmoisture_20: "20 cm",
          soilmoisture_30: "30 cm",
          soilmoisture_40: "40 cm",
          soilmoisture_60: "60 cm",
          soilmoisture_100: "100 cm",
        },
      },
      {
        label: "Observer",
        attr: {
            // "observer_id" : "ID observer",
            "observer_name" : "Nama observer "
            },
    },
    ];

    return listAttr;
  },
};
