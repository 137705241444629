import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Frekwensi",
        attr: {
          ww_cerah: "Cerah",
          ww_berawan_sebagian: "Berawan Sebagian",
          ww_berawan: "Berawan",
          ww_berawan_banyak: "Berawan Banyak",
          // obsawan_total_24: "Jumlah Pengamatan",
          jumlah_observasi : "Jumlah Pengamatan",
          ket_perawanan: "Keterangan",
        },
      },
      {
        label: "Observer",
        attr: {
            // "observer_id" : "ID observer",
            "observer_name" : "Nama observer "
            },
    },
    ];

    return listAttr;
  },
};
