import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
        {
            label: "Suhu",
            attr: {
                "m_0700ws[tbk_4m_0700]" :"suhu bola kering ketinggian 4m di jam 07.00",
                "m_1300ws[tbk_4m_1300]" :"suhu bola kering ketinggian 4m di jam 13.00",
                "m_1800ws[tbk_4m_1800]" :"suhu bola kering ketinggian 4m di jam 18.00",
                "m_0700ws[tbk_7m_0700]" :"suhu bola kering ketinggian 7m di jam 07.00",
                "m_1300ws[tbk_7m_1300]" :"suhu bola kering ketinggian 7m di jam 13.00",
                "m_1800ws[tbk_7m_1800]" :"suhu bola kering ketinggian 7m di jam 18.00",
                "m_0700ws[tbk_10m_0700]" :"suhu bola kering ketinggian 10m di jam 07.00",
                "m_1300ws[tbk_10m_1300]" :"suhu bola kering ketinggian 10m di jam 13.00",
                "m_1800ws[tbk_10m_1800]" :"suhu bola kering ketinggian 10m di jam 18.00",
                "tbk_avg_4" : "suhu bola kering rata-rata 4 m",
                "tbk_avg_7" : "suhu bola kering rata-rata 7 m",
                "tbk_avg_10" : "suhu bola kering rata-rata 10 m",
                "m_1800ws[t_max_4m]" :"suhu maksimum ketinggian 4m",
                "m_1800ws[t_max_7m]" :"suhu maksimum ketinggian 7m",
                "m_1800ws[t_max_10m]" :"suhu maksimum ketinggian 10m",
                "m_1300ws[t_min_4m]" :"suhu minimum ketinggian 4m",
                "m_1300ws[t_min_7m]" :"suhu minimum ketinggian 7m",
                "m_1300ws[t_min_10m]" :"suhu minimum ketinggian 10m",
                },
        },
        {
            label: "Relative Humidity",
            attr: {
                "m_0700ws[rh_4m_0700]" :"rel hum ketinggian 4m di jam 07.00",
                "m_1300ws[rh_4m_1300]" :"rel hum ketinggian 4m di jam 13.00",
                "m_1800ws[rh_4m_1800]" :"rel hum ketinggian 4m di jam 18.00",
                "m_0700ws[rh_7m_0700]" :"rel hum ketinggian 7m di jam 07.00",
                "m_1300ws[rh_7m_1300]" :"rel hum ketinggian 7m di jam 13.00",
                "m_1800ws[rh_7m_1800]" :"rel hum ketinggian 7m di jam 18.00",
                "m_0700ws[rh_10m_0700]" :"rel hum ketinggian 10m di jam 07.00",
                "m_1300ws[rh_10m_1300]" :"rel hum ketinggian 10m di jam 13.00",
                "m_1800ws[rh_10m_1800]" :"rel hum ketinggian 10m di jam 18.00",
                "rh_avg_4" : "rel hum rata ketinggian 4m",
                "rh_avg_7" : "rel hum rata ketinggian 7m",
                "rh_avg_10" : "rel hum rata ketinggian 10m",
                },
        },
        {
            label: "Kecepatan Angin",
            attr: {
                // "m_0700ws[ws_avg_4_0713]" :"Kec angin rata selisih antara 07 sd 13 ketinggian 4m",
                // "m_0700ws[ws_avg_7_0713]" :"Kec angin rata selisih antara 07 sd 13 ketinggian 7m",
                // "m_0700ws[ws_avg_10_0713]" :"Kec angin rata selisih antara 07 sd 13 ketinggian 10m",
                // "m_1300ws[ws_avg_4_1318]" :"Kec angin rata selisih antara 13 sd 18 ketinggian 4m",
                // "m_1300ws[ws_avg_7_1318]" :"Kec angin rata selisih antara 13 sd 18 ketinggian 7m",
                // "m_1300ws[ws_avg_10_1318]" :"Kec angin rata selisih antara 13 sd 18 ketinggian 10m",
                // "m_1800ws[ws_avg_4_1807]" :"Kec angin rata selisih antara 18 sd 07 ketinggian 4m",
                // "m_1800ws[ws_avg_7_1807]" :"Kec angin rata selisih antara 18 sd 07 ketinggian 7m",
                // "m_1800ws[ws_avg_10_1807]" :"Kec angin rata selisih antara 18 sd 07 ketinggian 10m",
                

                "ws_avg_4_0713" :"Kec angin rata selisih antara 07 sd 13 ketinggian 4m",
                "ws_avg_7_0713" :"Kec angin rata selisih antara 07 sd 13 ketinggian 7m",
                "ws_avg_10_0713" :"Kec angin rata selisih antara 07 sd 13 ketinggian 10m",
                "ws_avg_4_1318" :"Kec angin rata selisih antara 13 sd 18 ketinggian 4m",
                "ws_avg_7_1318" :"Kec angin rata selisih antara 13 sd 18 ketinggian 7m",
                "ws_avg_10_1318" :"Kec angin rata selisih antara 13 sd 18 ketinggian 10m",
                "ws_avg_4_1807" :"Kec angin rata selisih antara 18 sd 07 ketinggian 4m",
                "ws_avg_7_1807" :"Kec angin rata selisih antara 18 sd 07 ketinggian 7m",
                "ws_avg_10_1807" :"Kec angin rata selisih antara 18 sd 07 ketinggian 10m",
                },
        },
        {
            label: "Observer",
            attr: {
                // "observer_id" : "ID observer",
                "observer_name" : "Nama observer "
                },
        },
        ]
        return listAttr;
    }
    
}
