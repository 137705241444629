import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "SO2NO2",
        attr: {
          "so2no2_id_sampel":"Sampel ID",
          // "tgl_pasang":"Tanggal Pemasangan",
          // "tgl_angkat":"Tanggal Pengangkatan",
          "konsentrasi_so2":"konsentrasi SO2",
          "konsentrasi_no2":"konsentrasi NO2",
          "so2no2_ptgs_pasang_sampling":"Petugas Awal Sampling",
          "so2no2_ptgs_angkat_sampling":"Petugas Akhir Sampling",
          "so2no2_ptgs_analis":"Petugas Analis Lab",
          "so2no2_kondisi_cuaca_awal":"Kondisi cuaca awal",
          "so2no2_kondisi_cuaca_akhir":"Kondisi cuaca akhir",
          "so2no2_kondisi_sampling":"Kondisi sampling",
          "so2no2_kejadian_lainnya":"Catatan Lainnya lainnya",

        },
      },
    //   {
    //     label: "Observer",
    //     attr: {
    //         "observer_id" : "ID observer",
    //         "observer_name" : "Nama observer "
    //         },
    // },
    ];

    return listAttr;
  },
};
