import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Intentitas Hujan Dalam Menit",
        attr: {
          hellman_5m: "5 Menit",
          hellman_10m: "10 Menit",
          hellman_15m: "15 Menit",
          hellman_30m: "30 Menit",
          hellman_45m: "45 Menit",
          hellman_60m: "60 Menit",
          hellman_120m: "120 Menit",
        },
      },
      {
        label: "Intentitas Hujan Dalam Jam",
        attr: {
          hellman_3h: "3 Jam",
          hellman_6h: "6 Jam",
          hellman_12h: "12 Jam",
        },
      },
      {
        label: "Intentitas Hujan Per Jam",
        attr: {
          hellman_07_08: "07-08",
          hellman_08_09: "08-09",
          hellman_09_10: "09-10",
          hellman_10_11: "10-11",
          hellman_11_12: "11-12",
          hellman_12_13: "12-13",
          hellman_13_14: "13-14",
          hellman_14_15: "14-15",
          hellman_15_16: "15-16",
          hellman_16_17: "16-17",
          hellman_17_18: "17-18",
          hellman_18_19: "18-19",
          hellman_19_20: "19-20",
          hellman_20_21: "20-21",
          hellman_21_22: "21-22",
          hellman_22_23: "22-23",
          hellman_23_24: "23-24",
          hellman_24_01: "24-01",
          hellman_01_02: "01-02",
          hellman_02_03: "02-03",
          hellman_03_04: "03-04",
          hellman_04_05: "04-05",
          hellman_05_06: "05-06",
          hellman_06_07: "06-07",
        },
      },
      {
        label: "Observer",
        attr: {
            // "observer_id" : "ID observer",
            "observer_name" : "Nama observer "
            },
    },
    ];

    return listAttr;
  },
};
