import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
        {
            label: "Suhu bola kering",
            attr: {
                "m_0730ws[tbk_5cm_0730]" : "Suhu bola kering 5cm di jam 07.30",
                "m_1330ws[tbk_5cm_1330]" : "Suhu bola kering 5cm di jam 13.30",
                "m_1730ws[tbk_5cm_1730]" : "Suhu bola kering 5cm di jam 17.30",
                "m_0730ws[tbk_10cm_0730]" : "Suhu bola kering 10cm di jam 07.30",
                "m_1330ws[tbk_10cm_1330]" : "Suhu bola kering 10cm di jam 13.30",
                "m_1730ws[tbk_10cm_1730]" : "Suhu bola kering 10cm di jam 17.30",
                "m_0730ws[tbk_20cm_0730]" : "Suhu bola kering 20cm di jam 07.30",
                "m_1330ws[tbk_20cm_1330]" : "Suhu bola kering 20cm di jam 13.30",
                "m_1730ws[tbk_20cm_1730]" : "Suhu bola kering 20cm di jam 17.30",
                "m_0730ws[tbk_50cm_0730]" : "Suhu bola kering 50cm di jam 07.30",
                "m_1330ws[tbk_50cm_1330]" : "Suhu bola kering 50cm di jam 13.30",
                "m_1730ws[tbk_50cm_1730]" : "Suhu bola kering 50cm di jam 17.30",
                "m_0730ws[tbk_100cm_0730]" : "Suhu bola kering 100cm di jam 07.30",
                "m_1330ws[tbk_100cm_1330]" : "Suhu bola kering 100cm di jam 13.30",
                "m_1730ws[tbk_100cm_1730]" : "Suhu bola kering 100cm di jam 17.30",
                "m_0730ws[tbk_150cm_0730]" : "Suhu bola kering 150cm di jam 07.30",
                "m_1330ws[tbk_150cm_1330]" : "Suhu bola kering 150cm di jam 13.30",
                "m_1730ws[tbk_150cm_1730]" : "Suhu bola kering 150cm di jam 17.30",
                "m_0730ws[tbk_200cm_0730]" : "Suhu bola kering 200cm di jam 07.30",
                "m_1330ws[tbk_200cm_1330]" : "Suhu bola kering 200cm di jam 13.30",
                "m_1730ws[tbk_200cm_1730]" : "Suhu bola kering 200cm di jam 17.30",              
                },
        },
        {
            label: "Suhu bola basah",
            attr: {
                "m_0730ws[tbb_5cm_0730]" : "Suhu bola basah 5cm di jam 07.30",
                "m_1330ws[tbb_5cm_1330]" : "Suhu bola basah 5cm di jam 13.30",
                "m_1730ws[tbb_5cm_1730]" : "Suhu bola basah 5cm di jam 17.30",
                "m_0730ws[tbb_10cm_0730]" : "Suhu bola basah 10cm di jam 07.30",
                "m_1330ws[tbb_10cm_1330]" : "Suhu bola basah 10cm di jam 13.30",
                "m_1730ws[tbb_10cm_1730]" : "Suhu bola basah 10cm di jam 17.30",
                "m_0730ws[tbb_20cm_0730]" : "Suhu bola basah 20cm di jam 07.30",
                "m_1330ws[tbb_20cm_1330]" : "Suhu bola basah 20cm di jam 13.30",
                "m_1730ws[tbb_20cm_1730]" : "Suhu bola basah 20cm di jam 17.30",
                "m_0730ws[tbb_50cm_0730]" : "Suhu bola basah 50cm di jam 07.30",
                "m_1330ws[tbb_50cm_1330]" : "Suhu bola basah 50cm di jam 13.30",
                "m_1730ws[tbb_50cm_1730]" : "Suhu bola basah 50cm di jam 17.30",
                "m_0730ws[tbb_100cm_0730]" : "Suhu bola basah 100cm di jam 07.30",
                "m_1330ws[tbb_100cm_1330]" : "Suhu bola basah 100cm di jam 13.30",
                "m_1730ws[tbb_100cm_1730]" : "Suhu bola basah 100cm di jam 17.30",
                "m_0730ws[tbb_150cm_0730]" : "Suhu bola basah 150cm di jam 07.30",
                "m_1330ws[tbb_150cm_1330]" : "Suhu bola basah 150cm di jam 13.30",
                "m_1730ws[tbb_150cm_1730]" : "Suhu bola basah 150cm di jam 17.30",
                "m_0730ws[tbb_200cm_0730]" : "Suhu bola basah 200cm di jam 07.30",
                "m_1330ws[tbb_200cm_1330]" : "Suhu bola basah 200cm di jam 13.30",
                "m_1730ws[tbb_200cm_1730]" : "Suhu bola basah 200cm di jam 17.30",
                },
        },
        {
            label: "Kelembaban",
            attr: {
                "m_0730ws[rh_5cm_0730]" : "Kelembaban 5cm di jam 07.30",
                "m_1330ws[rh_5cm_1330]" : "Kelembaban 5cm di jam 13.30",
                "m_1730ws[rh_5cm_1730]" : "Kelembaban 5cm di jam 17.30",
                "m_0730ws[rh_10cm_0730]" : "Kelembaban 10cm di jam 07.30",
                "m_1330ws[rh_10cm_1330]" : "Kelembaban 10cm di jam 13.30",
                "m_1730ws[rh_10cm_1730]" : "Kelembaban 10cm di jam 17.30",
                "m_0730ws[rh_20cm_0730]" : "Kelembaban 20cm di jam 07.30",
                "m_1330ws[rh_20cm_1330]" : "Kelembaban 20cm di jam 13.30",
                "m_1730ws[rh_20cm_1730]" : "Kelembaban 20cm di jam 17.30",
                "m_0730ws[rh_50cm_0730]" : "Kelembaban 50cm di jam 07.30",
                "m_1330ws[rh_50cm_1330]" : "Kelembaban 50cm di jam 13.30",
                "m_1730ws[rh_50cm_1730]" : "Kelembaban 50cm di jam 17.30",
                "m_0730ws[rh_100cm_0730]" : "Kelembaban 100cm di jam 07.30",
                "m_1330ws[rh_100cm_1330]" : "Kelembaban 100cm di jam 13.30",
                "m_1730ws[rh_100cm_1730]" : "Kelembaban 100cm di jam 17.30",
                "m_0730ws[rh_150cm_0730]" : "Kelembaban 150cm di jam 07.30",
                "m_1330ws[rh_150cm_1330]" : "Kelembaban 150cm di jam 13.30",
                "m_1730ws[rh_150cm_1730]" : "Kelembaban 150cm di jam 17.30",
                "m_0730ws[rh_200cm_0730]" : "Kelembaban 200cm di jam 07.30",
                "m_1330ws[rh_200cm_1330]" : "Kelembaban 200cm di jam 13.30",
                "m_1730ws[rh_200cm_1730]" : "Kelembaban 200cm di jam 17.30",
                },
        },
        {
            label: "Observer",
            attr: {
                // "observer_id" : "ID observer",
                "observer_name" : "Nama observer "
                },
        },
        ]
        return listAttr;
    }
    
}
