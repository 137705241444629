import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
        {
            label: "Lysimeter",
            attr: {
                "m_1700ws[s_gundul]" : "air siraman tanah gundul",
                "m_1700ws[s_komoditi]" : "air siraman tanah komoditi",
                "m_1700ws[s_berumput]" : "air siraman tanah rumput",
                "m_1700ws[rr_gundul]" : "curah hujan di jam 17.00 gundul",
                "m_1700ws[rr_komoditi]" : "curah hujan di jam 17.00 komoditi",
                "m_1700ws[rr_berumput]" : "curah hujan di jam 17.00 berumput",
                "m_1700ws[pk_gundul]" : "air perkolasi gundul",
                "m_1700ws[pk_komoditi]" : "air perkolasi komoditi",
                "m_1700ws[pk_berumput]" : "air perkolasi rumput",
                "m_1700ws[eva_gundul]" : "Evapotranspirasi gundul",
                "m_1700ws[eva_komoditi]" : "Evapotranspirasi komoditi",
                "m_1700ws[eva_berumput]" : "Evapotranspirasi rumput",
                "m_1700ws[cat_lysimeter_gundul]" : "catatan lysimeter gundul",
                "m_1700ws[cat_lysimeter_berumput]" : "catatan lysimeter berumput",
                "m_1700ws[cat_lysimeter_komoditi]" : "catatan lysimeter komiditi",

                },
        },
        {
            label: "Observer",
            attr: {
                // "observer_id" : "ID observer",
                "observer_name" : "Nama observer "
                },
        },
        ]
        return listAttr;
    }
    
}
