import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Tekanan Udara",
        attr: {
          po_baro_07: "Jam 7",
          po_baro_08: "Jam 8",
          po_baro_09: "Jam 9",
          po_baro_10: "Jam 10",
          po_baro_11: "Jam 11",
          po_baro_12: "Jam 12",
          po_baro_13: "Jam 13",
          po_baro_14: "Jam 14",
          po_baro_15: "Jam 15",
          po_baro_16: "Jam 16",
          po_baro_17: "Jam 17",
          po_baro_18: "Jam 18",
          po_baro_19: "Jam 19",
          po_baro_20: "Jam 20",
          po_baro_21: "Jam 21",
          po_baro_22: "Jam 22",
          po_baro_23: "Jam 23",
          po_baro_24: "Jam 24",
          po_baro_01: "Jam 1",
          po_baro_02: "Jam 2",
          po_baro_03: "Jam 3",
          po_baro_04: "Jam 4",
          po_baro_05: "Jam 5",
          po_baro_06: "Jam 6",
          po_baro_avg_24: "Rata-rata",
        },
      },
      {
        label: "Observer",
        attr: {
            // "observer_id" : "ID observer",
            "observer_name" : "Nama observer "
            },
    },
    ];

    return listAttr;
  },
};
