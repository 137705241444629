import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Intentitas Radiasi Matahari",
        attr: {
          f_5_6: "5-6",
          f_6_7: "6-7",
          f_7_8: "7-8",
          f_8_9: "8-9",
          f_9_10: "9-10",
          f_10_11: "10-11",
          f_11_12: "11-12",
          f_12_13: "12-13",
          f_13_14: "13-14",
          f_14_15: "14-15",
          f_15_16: "15-16",
          f_16_17: "16-17",
          f_17_18: "17_18",
          f_18_19: "18-19",
          f_19_20: "19-20",
          f_20_21: "20-21",
          f_21_22: "21-22",
          f_22_23: "22-23",
          f_23_24: "23-24",
          f_total19: "Total",
        },
      },
      {
        label: "Observer",
        attr: {
            // "observer_id" : "ID observer",
            "observer_name" : "Nama observer "
            },
    },
    ];

    return listAttr;
  },
};
