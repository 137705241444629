import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Hujan",
        attr: {
          rr_phk_mm: "RR PHK mm",
          rr_das_mm: "RR DAS mm",
        },
        
      },
      // {
      //   label: "Hujan Dasarian Dalam mm",
      //   attr: {
      //     rr_total_07_10: "RR DAS 1 (1-10) mm",
      //     rr_total_1_20: "RR DAS 2 (11-20) mm",
      //     rr_total_21_31: "RR DAS 3 (21-31) mm",
      //   },
      // },
      {
        label: "Observer",
        attr: {
            // "observer_id" : "ID observer",
            "observer_name" : "Nama observer "
            },
    },
    ];
//         RR DAS 1 (1-10) mm
// RR DAS 2 (11-20) mm
// RR DAS 3 (21-31) mm
    return listAttr;
  },
};
