import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "Fklim 71",
        attr: {
          "m_0700ws[tbk_1c2m_0700]": "Suhu bola kering 1.2 m 07.00",
          "m_1300ws[tbk_1c2m_1300]": "Suhu bola kering 1.2 m 13.00",
          "m_1800ws[tbk_1c2m_1800]": "Suhu bola kering 1.2 m 18.00",
          tbk_avg: "suhu bola kering rata-rata",
          "m_1800ws[t_max_1c2m]": "suhu maksimum ketinggian 1.2m",
          // "m_1300ws[t_min_4m]" : "suhu minimum jam 13.00 4m",
          // "m_1300ws[t_min_7m]" : "suhu minimum jam 13.00 7m",
          // "m_1300ws[t_min_10m]" : "suhu minimum jam 13.00 10m",
          "m_1300ws[t_min_1c2m]": "suhu minimum jam 13.00 ",
          "m_0700ws[rr_0700]": "curah hujan jam 07.00",
          "m_0700ws[ss_8]": "lama penyinaran 8 jam",
          "m_0700ws[cu_khusus]": "cuaca khusus",
          "m_1800ws[pp_qfe_0000]": "tekanan udara",
          "m_0700ws[rh_1c2m_0700]": "lembab udara pengamatan jam 07.00",
          "m_1300ws[rh_1c2m_1300]": "lembab udara pengamatan jam 13.00",
          "m_1800ws[rh_1c2m_1800]": "lembab udara pengamatan jam 18.00",
          rh_avg: "lembab udara rata-rata",
          ff_avg_km_jm: "Kecepatan rata-rata (km/jam)",
          wd_cardinal: "arah angin terbanyak",
          ff_max: "kecepetan angin terbesar ",
          wd_ff_max: "arah saat kec terbesar ",
        },
      },
      {
        label: "Observer",
        attr: {
            // "observer_id" : "ID observer",
            "observer_name" : "Nama observer "
            },
    },
    ];
    return listAttr;
  },
};
