import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
        {
            label: "AGM1B",
            attr: {
                "m_0730ws[ttr_5cm_0730]" : "Suhu tanah rumput kedalaman 5 Cm  07.30",
                "m_0730ws[ttr_10cm_0730]" : "Suhu tanah rumput kedalaman 10 Cm 07.30",
                "m_0730ws[ttr_20cm_0730]" : "Suhu tanah rumput kedalaman 20 Cm 07.30",
                "m_0730ws[ttg_5cm_0730]" : "Suhu tanah gundul kedalaman 5 Cm  07.30",
                "m_0730ws[ttg_10cm_0730]" : "Suhu tanah gundul kedalaman 10 Cm 07.30",
                "m_0730ws[ttg_20cm_0730]" : "Suhu tanah gundul kedalaman 20 Cm 07.30",
                "m_1330ws[ttr_5cm_1330]" : "Suhu tanah rumput kedalaman 5 Cm  13.30",
                "m_1330ws[ttr_10cm_1330]" : "Suhu tanah rumput kedalaman 10 Cm 13.30",
                "m_1330ws[ttr_20cm_1330]" : "Suhu tanah rumput kedalaman 20 Cm 13.30",
                "m_1330ws[ttg_5cm_1330]" : "Suhu tanah gundul kedalaman 5 Cm  13.30",
                "m_1330ws[ttg_10cm_1330]" : "Suhu tanah gundul kedalaman 10 Cm  13.30",
                "m_1330ws[ttg_20cm_1330]" : "Suhu tanah gundul kedalaman 20 Cm  13.30",
                "m_1730ws[ttr_5cm_1730]" : "Suhu tanah rumput kedalaman 5 Cm  17.30",
                "m_1730ws[ttr_10cm_1730]" : "Suhu tanah rumput kedalaman 10 Cm 17.30",
                "m_1730ws[ttr_20cm_1730]" : "Suhu tanah rumput kedalaman 20 Cm 17.30",
                "m_1730ws[ttr_50cm_1730]" : "Suhu tanah rumput kedalaman 50 Cm  17.30",
                "m_1730ws[ttr_100cm_1730]" : "Suhu tanah rumput kedalaman 100 Cm 17.30",
                "m_1730ws[ttg_5cm_1730]" : "Suhu tanah gundul kedalaman 5 Cm  17.30",
                "m_1730ws[ttg_10cm_1730]" : "Suhu tanah gundul kedalaman 10 Cm  17.30",
                "m_1730ws[ttg_20cm_1730]" : "Suhu tanah gundul kedalaman 20 Cm  17.30",
                "m_1730ws[ttg_50cm_1730]" : "Suhu tanah gundul kedalaman 50 Cm  17.30",
                "m_1730ws[ttg_100cm_1730]" : "Suhu tanah gundul kedalaman 100 Cm  17.30",
                "m_0730ws[ket_tanah_0730]" : "Keterangan tentang keadaan tanah 07.30",
                "m_0730ws[ket_hujan_0730]" : "Keterangan tentang jatuhnya hujan 07.30",
                // "m_0730ws[ws_avg_1b_0c5_1]" : "Kecepatan angin rata-rata",
                "m_0730ws[rr_0730]" : "curah hujan selama 14 jam antara jam 17.30 WS dan 07.30 WS",
                // "ws_avg_1b_0c5_1" : "Kecepatan angin rata-rata 07.30",
                "ws_avg_1b_0c5_1" : "Kecepatan angin rata-rata antara jam 17.30 WS dan 07.30 WS",
                "m_0730ws[ws_10_0730]" : "Kecepatan angin 10 meter pada jam 07.30 ",
                "m_0730ws[tbk_1c2m_0730]" : "Suhu udara bola kering pada jam 07.30 ",
                "m_0730ws[rh_1c2m_0730]" : "Lembab udara  pada  jam 07.30",
                "m_0730ws[t_air_avg_0730]" : "Suhu rata2 Air  jam 07.30",
                "m_0730ws[op_eva_0730]" : "Penguapan antara jam 17.30  sampai jam 07.30 ",
                // "m_1330ws[ws_avg_1b_0c5_2]" : "Kecepatan angin rata-rata",
                "m_1330ws[rr_1330]" : "curah hujan selama 6 jam antara jam 07.30 WS dan 13.30 WS",
                // "ws_avg_1b_0c5_2" : "Kecepatan angin rata-rata 13.30",
                "ws_avg_1b_0c5_2" : "Kecepatan angin rata-rata antara jam 07.30 WS dan 13.30 WS",
                "m_1330ws[ws_10_1330]" : "Kecepatan angin 10 meter pada jam 13.30 ",
                "m_1330ws[tbk_1c2m_1330]" : "Suhu udara bola kering pada jam 13.30 ",
                "m_1330ws[rh_1c2m_1330]" : "Lembab udara  pada  jam 13.30 ",
                "m_1330ws[t_air_avg_1330]" : "Suhu rata2 Air  jam 13.30 ",
                "m_1330ws[op_eva_1330]" : "Penguapan antara jam 07.30  sampai jam 13.30  ",

                "m_1730ws[rr_1730]" : "curah hujan selama 4 jam antara jam 13.30 WS dan 17.30 WS",
                // "ws_avg_1b_0c5_3" : "Kecepatan angin rata-rata 17.30",
                "ws_avg_1b_0c5_3" : "Kecepatan angin rata-rata antara jam 13.30 WS dan 17.30 WS",
                // "m_1730ws[ws_avg_1b_0c5_3]" : "Kecepatan angin rata-rata",
                "m_1730ws[ws_10_1730]" : "Kecepatan angin 10 meter pada jam 17.30 ",
                "m_1730ws[tbk_1c2m_1730]" : "Suhu udara bola kering pada jam 17.30 ",
                "m_1730ws[rh_1c2m_1730]" : "Lembab udara  pada  jam 17.30 ",
                "m_1730ws[t_air_avg_1730]" : "Suhu rata2 Air  jam 17.30 ",
                "m_1730ws[op_eva_1730]" : "Penguapan antara jam 13.30  sampai jam 17.30 ",
                "sum_ch_1b" : "Jumlah curah hujan antara jam 17.30 kemarin sampai dengan jam 17.30 hari ini",
                "sum_eva_1b" : "Jumlah penguapan antara jam 17.30 kemarin sampai dengan jam 17.30 hari ini",
                "ket_1b" : "hal-hal yang perlu diterangkan",
                // "m_1730ws[sum_ch_1b]" : "Jumlah curah hujan antara jam 17.30 kemarin sampai dengan jam 17.30 hari ini",
                // "m_1730ws[sum_eva_1b]" : "Jumlah penguapan antara jam 17.30 kemarin sampai dengan jam 17.30 hari ini",
                // "m_1730ws[ket_1b]" : "hal-hal yang perlu diterangkan",
                },
        },
        {
            label: "Observer",
            attr: {
                // "observer_id" : "ID observer",
                "observer_name" : "Nama observer "
                },
        },
        ]
        return listAttr;
    }
    
}
