import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper";
export default {
  getAttr() {
    const listAttr = [
      {
        label: "SPM",
        attr: {
          "spm_id_sampel":"Sampel ID",
          // "tgl_pasang":"Tanggal Pemasangan",
          // "tgl_angkat":"Tanggal Pengangkatan",
          "spm_konsentrasi":"Konsentrasi SPM",
          "spm_bobot_filter_kosong":"Bobot Filter Kosong",
          "spm_bobot_filter_sampling":"Bobot Filter Sampling",
          "spm_flow_rate_awal":"Flow Rate Awal",
          "spm_flow_rate_akhir":"Flow Rate Akhir",
          "spm_total_waktu_sampling":"Total Waktu Sampling (menit)",
          "spm_tekanan_udara_awal":"Tekanan udara awal",
          "spm_tekanan_udara_akhir":"Tekanan udara akhir",
          "spm_temperatur_awal":"Temperature awal",
          "spm_temperatur_akhir":"Temperature akhir",
          "spm_ptgs_pasang_sampling":"Petugas Awal Sampling",
          "spm_ptgs_angkat_sampling":"Petugas Akhir Sampling",
          "spm_ptgs_analis":"Petugas Analis Lab",
          "spm_kondisi_cuaca_awal":"Kondisi cuaca awal",
          "spm_kondisi_cuaca_akhir":"Kondisi cuaca akhir",
          "spm_kondisi_sampling":"Kondisi sampling",
          "spm_kejadian_lainnya":"Catatan Lainnya lainnya"
        },
      },
    //   {
    //     label: "Observer",
    //     attr: {
    //         "observer_id" : "ID observer",
    //         "observer_name" : "Nama observer "
    //         },
    // },
    ];

    return listAttr;
  },
};
